<template>
  <div class="product">
    <div style="min-height:0.6rem;background-color:#414141" :style="{display:showFixed?'block':'none'}"></div>
    <div class="headBox" :style="{position:showFixed?'fixed':'',top:showFixed?'0':''}">
      <div class="head">
        <div class="headName">{{Name}}</div>
        <div class="headRight">
          <router-link :to="urlOne" class="view" :style="{color:col?'#fff':''}">概览</router-link>
          <router-link :to="urlTwo" class="param" :style="{color:col?'':'#fff'}">安装参数</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["name", "add", "adds", "color"],
  data() {
    return {
      Name: this.name,
      urlOne: this.add,
      urlTwo: this.adds,
      col: this.color,
      scrollTop: " 1.8rem",
      showFixed: false,
      n: ""
    };
  },
  created() {
    this.handleScroll();
    var that = this;
    window.screenWidth = document.body.clientWidth;
    that.n = window.screenWidth / 19.2;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.n = window.screenWidth / 19.2;
      })();
    };
  },
  methods: {
    handleScroll() {
      window.addEventListener("scroll", () => {
        this.scrollTop = window.scrollY;
      });
    }
  },
  watch: {
    // 监测滚动距离固定导航条
    scrollTop() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 1.8 * this.n) {
        this.showFixed = true;
      } else {
        this.showFixed = false;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.headBox {
  width: 100%;
  height: 0.6rem;
  background: #414141;
  // position: fixed;
  // left: 0;
  // top: 1.8rem;
  z-index: 99;
  .head {
    width: 12rem;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.18rem;
    font-weight: bold;
    color: #999999;
    .headRight {
      display: flex;
      a {
        font-size: 0.18rem;
        font-weight: 400;
        color: #999999;
        &:first-of-type {
          font-weight: bold;
        }
        &:last-of-type {
          margin-left: 0.61rem;
        }
      }
    }
  }
}
</style>
