<template>
  <div class="air">
     <!-- 参数 -->
    <Head :name="'空调面板'" :add="'/pc/ProAir'" :adds="'/pc/ProAirParam'" :color="true" />
    <div class="banner">
      <div class="bannerBox">
        <div class="title">随心控制 舒适智能</div>
        <div class="text">带给你不同与传统智能家居的新体验</div>
        <div class="tip">
          <div class="tips">隔绝干扰</div>
          <div class="tips">远程控制</div>
          <div class="tips">语音控制</div>
        </div>
      </div>
    </div>
    <!-- 便捷 -->
    <div class="convenient">
      <div class="conTit">随时随地享受科技带来的便捷</div>
      <div class="conBox">
        <div class="conLi">
          <img class="liImg" src="../../../assets/product/air1.png" alt />
          <div class="liTit">专有技术 稳定抗干扰</div>
          <div class="liText">产品采用专用频段，有效隔绝干扰</div>
        </div>
        <div class="conLi">
          <img class="liImg" src="../../../assets/product/air2.png" alt />
          <div class="liTit">智能音箱 语音控制</div>
          <div class="liText">接入智能音箱，只需说句话就能轻松控制空调</div>
        </div>
        <div class="conLi">
          <img class="liImg" src="../../../assets/product/air3.png" alt />
          <div class="liTit">手机小程序 远程控制</div>
          <div class="liText">打开微信小程序，随时随地控制空调</div>
        </div>
      </div>
    </div>
    <!-- 科技 -->
    <div class="technology">
      <div class="technologyBox">
        <div class="teTit">贴心设计，让科技更懂你</div>
        <div class="teSec">
          <div class="secTit">操作简单，无需思考</div>
          <div class="secText">简单而符合直觉的界面设计，在提升效率的同时又不失掌控</div>
        </div>
        <div class="teSec">
          <div class="secTit">手机操控，方便快捷</div>
          <div class="secText">拿起手机一键开关，简单又快捷</div>
        </div>
        <div class="teSec">
          <div class="secTit">实时监控，安全省心</div>
          <div class="secText">出门在外，轻松获取室内温度</div>
          <div class="secText secTexts">提前打开空调，开门即享清凉</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Head from "../../../components/Head.vue";
export default {
  components: {
    Head
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  height: 8rem;
  background: url("../../../assets/product/airBanner.png") ;
  background-size: 100% 100%;
  .bannerBox {
    width: 12rem;
    margin: 0 auto;
    text-align: right;
    overflow: hidden;
    color: #333333;
    display: flex;
    flex-direction: column;
    .title {
      margin-top: 5.53rem;
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
    }
    .text {
      margin-top: 0.16rem;
      font-size: 0.3rem;
      line-height: 0.3rem;
      font-weight: 400;
    }
    .tip {
      display: flex;
      justify-content: flex-end;
      margin-top: 0.7rem;
      font-size: 0.24rem;
      font-weight: bold;
      color: #ff9000;
      .tips {
        height: 0.24rem;
        line-height: 0.24rem;
        padding: 0px 0.2rem;
        border-right: 1px solid #333;
        &:last-of-type {
          padding-right: 0px;
          border-right: 0px;
        }
      }
    }
  }
}
// 便捷
.convenient {
  width: 100%;
  background-color: #f8f8f8;
  padding: 1.2rem 0px;
  color: #333333;
  .conTit {
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
  }
  .conBox {
    margin-top: 0.81rem;
    width: 100%;
    display: flex;
    .conLi {
      flex: 1;
      padding-right: 0.3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      &:last-of-type{
        padding-right: 0px;
      }
      .liImg {
        width: 100%;
      }
      .liTit {
        margin-top: 0.6rem;
        font-size: 0.24rem;
        line-height: 0.24rem;
        font-weight: bold;
        color: #333333;
      }
      .liText {
        margin-top: 0.21rem;
        font-size: 0.18rem;
        line-height: 0.18rem;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
// 科技
.technology {
  width: 100%;
  padding: 1.2rem 0;
  
  .technologyBox {
    width: 12rem;
    margin: 0 auto;
    .teTit {
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #333333;
      margin-bottom: 0.79rem;
    }
    .teSec {
      width: 100%;
      height:6rem;
      background-image: url("../../../assets/product/air4.png");
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      color: #ffffff;
      &:nth-of-type(3){
          background-image: url("../../../assets/product/air5.png");
          color: #333333;
      }
      &:nth-of-type(4) {
          background-image: url("../../../assets/product/air6.png");
        color: #333333;
      }
      .secTit {
        margin-top: 0.59rem;
        font-size: 0.36rem;
        line-height: 0.36rem;
        font-weight: 800;
        
      }
      .secText {
        margin-top: 0.28rem;
        font-size: 0.24rem;
        line-height: 0.24rem;
        font-weight: 400;
      }
      .secTexts {
        margin-top: 0.12rem;
      }
    }
  }
}

</style>
